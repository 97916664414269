.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: "white";
    color: black;
    z-index: 1000;
}

.loadingText {
    font-family: 'Golos Regular';
    margin-top: 20px;
    font-size: 20px;
}

.customCircularProgress {
    color: #AB70FF !important;
}

.customCircularProgress svg circle {
    stroke-width: 2;
}
