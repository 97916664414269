    .modalOverlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        align-items: center;
        opacity: 0;
        pointer-events: none;
    }

    .modalOverlay.visible {
        opacity: 1;
        pointer-events: all;
    }

    .modalContent {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: 100vh;
    }

    .modalDarkArea {
        flex: 2;
    }

    .modalInfoArea {
        will-change: transform;
        backface-visibility: hidden;
        flex: 1;
        background-color: var(--modal-bg-color);
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out, background-color 0.5s ease-in;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modalInfoArea.visible {
        transform: translateX(0);
    }

    .modalOverlay.closing .modalInfoArea {
        transform: translateX(100%);
    }

    .modalOverlay.fullScreen {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modalInfoArea.fullScreenContent {
        margin-top: 5em;
        margin-bottom: 5em;
        margin-left: 10em;
        margin-right: 10em;
        transform: none;
        transition: none;
        position: relative;
    }

    .modalOverlay.fullScreen .modalDarkArea {
        display: none;
    }

    .modalOverlay.isCentered .modalDarkArea {
        display: none;
    }

    .modalOverlay.isCentered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modalInfoArea.isCenteredContent {
        padding-right: 3em;
        transform: none;
        transition: none;
        position: relative;
        max-width: 35%;
        max-height: 100%;
        margin: auto;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    @keyframes centerModalAnimation {
        from {
            transform: scale(0.5);
            opacity: 0;
        }
        to {
            transform: scale(1);
            opacity: 1;
        }
    }


    .modalInfoArea.isCenteredContent.visible {
        animation: centerModalAnimation 0.3s ease-in-out forwards;
    }


    .modalInfoArea::-webkit-scrollbar {
        width: 5px;
    }

    .modalInfoArea::-webkit-scrollbar-thumb {
        background: #A2C4FF;
        border-radius: 10px;
    }

    .modalInfoArea::-webkit-scrollbar-thumb:hover {
        background: #D0E1FF;
    }


    .modalOverlay.fullScreenAll {
        display: flex;
    }

    .modalInfoArea.fullScreenContent, .modalOverlay.fullScreenAll .modalInfoArea {
        width: 100vw;
        height: 100vh;
        margin: 0;
        max-width: none;
        max-height: none;
        transform: none;
        transition: none;
    }

    .modalOverlay.fullScreenAll .modalDarkArea {
        display: none;
    }
