.mainIputsContainer {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    gap: 5em;
    position: relative;
}

@media (max-width: 1000px) {
    .mainIputsContainer {
        margin-top: 1em;
        flex-direction: column;
        padding: 1em;
        gap: 2em;
    }

    .dynamicForm {
        order: 2;
    }

    .tarrifContainer {
        order: 1;
    }
}
