.contactFormContainer {
    width: 90%;
    position: relative;
    z-index: 3;
}

.inputField, .textAreaField {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    padding-left: 16px;
    border: 1px solid #F7FAFC;
    border-radius: 8px;
    background: #F7FAFC;
    transition: border-color 0.3s;
}

.inputField:focus,
.textAreaField:focus {
    border-color: #4A3DFD;
    background: #FFFFFF;
    outline: none;
}

.inputField::placeholder,
.textAreaField::placeholder {
    transition: transform 0.3s, opacity 0.3s;
    transform-origin: bottom left;
    opacity: 1;
    font-size: 15px;
    color: #8D96B2;
}

.inputField:focus::placeholder,
.textAreaField:focus::placeholder {
    transform: translateY(-20px);
    opacity: 0;
}

.textAreaField {
    height: 100px;
    resize: none;
    margin-bottom: 0;
}

.submitButton {
    width: 100%;
    padding: 12px 16px;
    background-color: #4A3DFD;
    color: white;
    font-size: 17px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.submitButton:enabled {
    opacity: 1;
}

.inputError {
    border-color: red;
}

.charLimitText, .disclaimerText {
    font-size: 12px;
    color: #8D96B2;
    display: block;
    text-align: left;
    margin-bottom: 24px;
}

.headerText {
    font-family: "Golos Medium";
    color: #333;
    text-align: center;
    font-size: 40px;
    line-height: 48px;
}

.emailContainer {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.disclaimerWrapper {
    text-align: left;
    align-items: center;
    flex-wrap: nowrap;
}

.disclaimerText, .termsLink {
    display: inline;
    margin: 0;
}

.termsLink {
    font-size: 12px;
    color: #4A3DFD;
    cursor: pointer;
    text-decoration: none;
}

.termsLink:hover {
    text-decoration: underline;
}

@media (max-width: 900px) {
    .contactFormContainer {
        width: 90%;
    }
    .inputForm {
        padding-right: 2em;
        padding-left: 0.5em;
    }
    .headerText {
        font-size: 28px;
    }
    .card {
        max-width: 420px;
    }
    .emailContainer {
        flex-direction: column;
        gap: 0;
    }
    .inputField::placeholder,
    .textAreaField::placeholder {
        font-size: 1em;
    }
    .charLimitText, .disclaimerText {
        font-size: 0.8em;
    }
    .disclaimerWrapper {
        margin-top: 1em;
        margin-bottom: 2em;
    }
    .contactFormContainer {
       max-width: 90vw;
    }
}
