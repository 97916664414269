.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.donut {
    width: 70px;
    height: 70px;
}

.donut-hole,
.donut-segment {
    fill: none;
    stroke-width: 6;
}

.donut-hole {
    stroke: #fff;
}

.donut-segment {
    stroke: #AB70FF;
    stroke-dasharray: 283;
    stroke-dashoffset: 283;
    animation: fillDonut 0.61s linear infinite;
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

@keyframes fillDonut {
    0% {
        stroke-dashoffset: 283;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
