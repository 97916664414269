.settingsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
        "tariffs info"
        "settings settings";
    gap: 16px;
    width: 100%;
    padding: 36px;
    box-sizing: border-box;
}

.tariffs {
    grid-area: tariffs;
}

.info {
    grid-area: info;
}

.settings {
    grid-area: settings;
}

.personalHeader {
    color: var(--text-color);
}
