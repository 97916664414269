.card {
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    padding: 0.8em;
    margin-bottom: 20px;
    box-shadow: 0px 0px 40px 0px rgba(97, 106, 136, 0.20);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.date {
    font-size: 16px;
    color: #8D96B2;
}

.titleString {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    color: #1B2134;
    font-family: "Golos Medium";
    font-size: 26px;
    transition: color 0.3s;
    width: 80%;
}

.title:hover {
    color: #4A3DFD;
    cursor: pointer;
}

.price {
    color: #1B2134;
    font-size: 26px;
    font-family: "Golos Medium";
}

.description {
    width: 80%;
    color: #1B2134;
    line-height: 1.5;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.buttonContFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

.likeButton {
    background: none;
    border: none;
    cursor: pointer;
}

.detailsButton {
    background-color: #4A3DFD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s;
}

.detailsButton:hover {
    background-color: #3733C9;
}

.rigthSmallBt {
    width: 125px;
}

.contractGrowth {
    color: #29C18A;
    margin-left: 2px;
}

.titleOrder {
    color: #29C18A;
    font-size: 16px;
}

.numberSt {
    margin-bottom: 3em;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.trashIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.linkRef {
    cursor: pointer;
    color: #4A3DFD;
}

.details {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    margin-bottom: 1em;
    gap: 0.5em;
    width: 100%;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.detailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.toastContainer {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}
