.okpdContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 35rem;
    min-height: 35rem;
}

.areaContainer {
    padding: 0.5em;
    margin-left: -0.5em;
    min-height: 15rem;
    max-height: 30rem;
}

.scrollableList {
    overflow-y: auto;
    flex-grow: 1;
}

.counter {
    display: inline-block;
    margin-bottom: 0.5em;
}

.formControl {
    width: 100%;
    margin-bottom: 10px !important;
}

.limitContainer {
    margin-bottom: 0.3em;
}
