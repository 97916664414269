.toast {
    position: fixed;
    top: 20px;
    right: -300px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 1;
}

.toast.add {
    background-color: #4caf50;
    color: #fff;
}

.toast.remove {
    background-color: #f44336;
    color: #fff;
}

.toast.show {
    right: 20px;
}

.toast.hide {
    opacity: 0;
    right: -300px;
}
