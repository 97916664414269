.tarrifsContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 5em;
    position: relative;
    z-index: 3;
}

.tarrifListContainer {
    padding: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1380px;
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    position: relative;
    z-index: 3;
}

.cardListContainer {
    width: 100%;
    margin-top: 56px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
}

.sliderContainer {
    width: 100%;
    margin-top: 2em;
    height: auto;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.gradientCircleTarrifB1,
.gradientCircleTarrifB2 {
    z-index: 2;
    position: absolute;
    pointer-events: none;
}

.gradientCircleTarrifB1 {
    max-width: 1100px;
    max-height: 1100px;
}

.gradientCircleTarrifB2 {
    max-width: 800px;
    max-height: 800px;
}

.tarrifCircleB1 {
    right: 55%;
    bottom: -10%;
}

.tarrifCircleB2 {
    right: 32%;
    bottom: -10%;
}

.tarrifCircleB3 {
    right: 10%;
    bottom: -10%;
}

.tarrifCircleB4 {
    right: -35%;
    top: -40%;
}

.tarrifCircleB5 {
    right: -30%;
    top: 20%;
}

.toggleContainer {
    display: flex;
    background-color: #EDF1F5;
    padding: 5px;
    position: relative;
    width: max-content;
    margin: auto;
    border-radius: 24px;
}

.toggleButton {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-size: 16px;
    border-radius: 24px;
}

.twMonthButton {
    padding-right: 2px;
    padding-left: 10px;
}

.toggleButtonActive {
    background-color: white;
    color: #8D96B2;
}

.discount {
    background-color: #EADCFF;
    color: #4F19AB;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 24px;
    transition: background-color 0.3s ease-in-out;
}

.dropdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.dropdown {
    width: 100%;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
@media (max-width: 1024px) {
    .containterGov {
        gap: 0;
    }

    .cardListContainer {
        display: none;
    }
}

@media (max-width: 767px) {
    .containterGov {
        flex-wrap: wrap;
    }
}

@media (min-width: 1025px) {
    .sliderContainer {
        display: none;
    }
}
