.rf-map {
    width: 100%;
    height: 100%;
    max-width: 94%;
    max-height: 90%;
}

.colorScaleContainer {
    margin-bottom: 30px;
    font-family: "Golos Regular";
    color: #8D96B2;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.colorBox {
    height: 10px;
    width: 10px;
}

.mapAndScaleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rf-map path {
    stroke: var(--text-color) !important;
    stroke-width: 0.5;
    stroke-linejoin: round;
    transition: fill 0.5s ease;
    fill: #f0f0f0
}

.tooltip {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    position: fixed;
    font-family: 'Golos Regular';
    max-width: 300px;
    background-color: #FFF;
    color: #315EFB;
    padding: 0.7em;
    border-radius: 5px;
    font-size: 12px;
    pointer-events: none;
    white-space: pre-wrap;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.labelName {
    margin-right: 0.5em;
    color: #8D96B2;
}
