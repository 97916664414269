.tableContainer {
    display: grid;
    gap: 10px;
    overflow-y: auto;
    max-height: 70vh;
}

.icoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px
}

.soloIco {
    justify-content: center;
}

.icoTest {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.availableContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-left: 16px;
    font-size: 14px;
    color: #8D96B2;
}

.plusContainer {
    padding: 0.7em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    border-radius: 8px;
    background: #F7FAFC;
    color: #4A3DFD;
    gap: 0.2em;
    right: 0.5em;
    top: -3em;
    transform: translateY(-50%);
}

.plusIco {
    width: 1.2em;
    height: 1.2em;
}

.tableCellText {
    transition: color 0.5s ease-in;
    color: var(--text-color) !important;
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}

.textCentered {
    text-align: center !important;
}

.column1 {
    width: 20%;
}

.column2 {
    width: 60%;
}

.column4 {
    width: 13%;
}

.column3 {
    text-align: center !important;
    width: 5%;
}

@media (max-width: 600px) {
    .tableContainer {
        grid-template-columns: 1fr;
    }
}

html::-webkit-scrollbar, .tableContainer::-webkit-scrollbar {
    height: 5px;
}

html::-webkit-scrollbar-thumb, .tableContainer::-webkit-scrollbar-thumb {
    background: #A2C4FF;
    border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover, .tableContainer::-webkit-scrollbar-thumb:hover {
    background: #D0E1FF;
}

