.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 5px;
    flex-shrink: 0;
    height: 24px;
}

.placeholderIcon {
    width: 24px;
    height: 24px;
}

.iconWrapperHidden {
    visibility: hidden;
    width: 0;
}

.childrenList {
    margin-top: 0.3em;
    margin-left: 20px;
    list-style-type: none;
    padding-left: 0;
}

.treeNodeHeader {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    cursor: default;
    overflow: hidden;
}

.okpdCode {
    margin-right: 5px;
}

.treeNode {
    margin-bottom: 0.5em;
}
