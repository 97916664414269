@font-face {
    font-family: 'Golos Regular';
    src: url('golos-ui_regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Golos Medium';
    src: url('golos-ui_medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Golos Bold';
    src: url('golos-ui_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

