:root {
    --container-bg-color: #FFF;
    --main-container-bg-color: #F7FAFC;
    --border-color: #F7FAFC;
    --text-color: black;
    --switcher-item-active-bg-color: white;
    --switcher-container-bg-color: #F7FAFC;
    --font-family: 'Golos Regular', sans-serif;
    --sliderBtn-bg-color: #FFF;
    --headerCmg-bg-color: #F7FAFC;
    --scroll-bg-color: #A2C4FF;
    --modal-bg-color: #FFF;
    --badge-bg-color: #4A3DFD;
    --tabText-color: #4A3DFD;
    --switcherBt-bg-color: #EDF1F5;
    --switcherBt-text-color: #8D96B2;
}
html, body {
    font-family: var(--font-family) !important;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.MuiInputBase-input  {
    background: white;
    border-radius: 12px;
}
.MuiTextField-root {
    border-radius: 12px;
    background: white;
}
.MuiTextField-root .MuiInputBase-input:placeholder-shown {
    border-radius: 12px;
}

.MuiTableCell-root {
    font-family: var(--font-family) !important;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: var(--font-family) !important;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
    width: 5px;
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    background: var(--scroll-bg-color);
    border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
    background: #D0E1FF;
}

html::-webkit-scrollbar, div::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

html::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
    background: var(--scroll-bg-color);
    border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover, div::-webkit-scrollbar-thumb:hover {
    background: #D0E1FF;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
    background: var(--container-bg-color);
}

div::-webkit-scrollbar-track {
    background: var(--container-bg-color);
}

.autocomplete-custom .MuiOutlinedInput-root {
    border-radius: 6px;
    background-color: #F7FAFC;
}

.autocomplete-custom .MuiOutlinedInput-root fieldset {
    border-color: #8D96B2;
}

.autocomplete-custom .MuiOutlinedInput-root:hover fieldset {
    border-color: #C8A1FF;
}

.autocomplete-custom .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #4A3DFD;
}

.autocomplete-custom .MuiInputLabel-root {
    font-family: 'Golos Regular';
    font-size: 14px;
}

.autocomplete-custom .MuiInputBase-input {
    font-family: 'Golos Regular';
    font-size: 14px;
}

.autocomplete-custom .MuiFormHelperText-root {
    font-family: 'Golos Regular';
    font-size: 14px;
}

.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range{
    background-color: white !important;
    color: black !important;
}

.react-datepicker__header {
    border-bottom-color: transparent !important;
    background-color: transparent !important;
}

.react-datepicker-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.react-datepicker {
    border-radius: 10px !important;
    border: 1px solid transparent !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker__month {
    margin: 0.8rem !important;
    width: auto;
    background-color: white !important;
}
.react-datepicker__month-text {
    padding: 5px 0;
    text-transform: capitalize;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: #4B72FB !important;
    color: white !important;
    border-radius: 4px;
    padding: 5px 0;
}

.react-datepicker__month-text:hover {
    background-color: #EADCFF !important;
    padding: 5px 0;
    cursor: pointer;
}

.react-datepicker__triangle {
    stroke: transparent !important;
}

.react-datepicker__navigation--next {
    right: 0.3em !important;
}

.react-datepicker__navigation--previous {
    left: 0.3em !important;
}

.react-datepicker__tab-loop__start {
    display: none !important;
}

