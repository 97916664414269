.inputError {
    border-color: red;
}

.inputField {
    width: 100%;
    height: 2em;
    padding: 8px;
    padding-right: 30px;
    margin-bottom: 16px;
    padding-left: 16px;
    border: 1px solid #F7FAFC;
    border-radius: 8px;
    background: #F7FAFC;
    transition: border-color 0.3s;
}

.inputField:focus {
    border-color: #4A3DFD;
    background: #FFFFFF;
    outline: none;
}

.inputField::placeholder {
    transition: transform 0.3s, opacity 0.3s;
    transform-origin: bottom left;
    opacity: 1;
    font-size: 15px;
    color: #8D96B2;
}

.inputField:focus::placeholder {
    transform: translateY(-20px);
    opacity: 0;
}

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.endAdornment {
    position: absolute;
    right: 20px;
    top: 22px
}
