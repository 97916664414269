.likeResultsContainer {
    padding: 20px;
}

.resultsList {
    display: flex;
    flex-direction: column;
}

.headerTitle {
    font-size: 26px;
    font-family: 'Golos Medium';
}

.headerReturnText {
    color: #8D96B2;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 1em;
}

.returnSearchContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}
