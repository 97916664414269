.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #fff;
    padding: 48px;
    border-radius: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
    text-align: center;
    position: relative;
}

.modalHeading {
    font-size: 34px;
    font-family: 'Golos Medium';
    margin-bottom: 40px;
}

.modalDesc {
    text-align: start;
    font-size: 16px;
    margin-bottom: 40px;
}

.codeInputContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.codeInput {
    width: 48px;
    height: 48px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #F7FAFC;
    border-radius: 6px;
    background: #F7FAFC;
}

.errorMessage {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}

.submitButton {
    width: 100%;
    background-color: #4A3DFD;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 40px;
}

