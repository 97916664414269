.legendContainer {
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    gap: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
}

.legendItem {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.legendItem span {
    color: var(--text-color);
    transition: color 0.3s ease-in;
    white-space: nowrap;
}

.legendButton {
    width: 20px;
    height: 14px;
    background-color: #62687F;
    border-radius: 3px;
    font-size: 10px;
    font-family: 'Golos Regular';
    color: #F7FAFC;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.legendContainer::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.legendContainer::-webkit-scrollbar-thumb {
    background: var(--scroll-bg-color);
    border-radius: 10px;
}

.legendContainer::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-bg-color);
}
