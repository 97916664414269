.background {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../../common/background.webp');
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    pointer-events: none;
}

.modalInputField {
    width: 100%;
}

.inputContainer {
    width: 100%;
}
.authButton {
    background: #4A3DFD !important;
    color: white !important;
    font-size: 17px;
    text-transform: none !important;
    padding: 8px 16px;
    border-radius: 8px !important;
}

.loading {
    width: 100%;
    height: 100%;
    color: #FFFFFF;
}
