.headerText {
    margin-left: 1%;
    font-size: 24px;
    font-family: "Golos Medium";
}

.noMrHeaderText {
    margin-top: 2em;
    margin-left: unset;
}

.additionalBtMr {
    margin-bottom: 1em;
}

.mainInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8em;
}

.gridContainerSmG {
    margin-top: 1em;
    gap: 0.1em 8em;
}

.gridItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
}

.gridItemMrB {
    margin-bottom: 0.3em;
}

.infoLabel {
    font-size: 14px;
    line-height: 20px;
    color: #8D96B2;
    margin-bottom: 0.4em;
}

.infoValue {
    color: #1B2134;
    white-space: break-spaces;
}

.infoLink {
    color: #4A3DFD;
    text-decoration: none;
}

.linkRefer {
    margin-left: 0.1em;
    width: 0.6em !important;
    height: 0.6em !important;
}

.infoPercentage {
    font-size: 14px;
    line-height: 22px;
    color: #29C18A;
    margin-left: 0.5em;
}

.infoLink:hover {
    text-decoration: underline;
}

.nodeDetail {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.moreButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.moreButton {
    background-color: #4A3DFD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
}

.topContainer {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.empString {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
