.cropContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.labelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
