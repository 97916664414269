.tableContainer {
    margin-top: 2em;
    margin-left: 1em;
    overflow: auto;
    height: 77vh;
    width: 97.5%;
    border-radius: 16px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
}

.thTd {
    text-align: left;
    padding: 8px;
    color: #8D96B2;
}

.stickyHeader {
    position: sticky;
    top: 0;
    background: #f2f2f2;
    z-index: 2;
}

.stickyColumn {
    background: #fff;
    position: sticky;
    left: 0;
    z-index: 1;
}

.stickyColumnAdjacent {
    z-index: 3;
}

.stickyColumn.stickyHeader {
    z-index: 4;
}

.fColumnDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.5em;
}
