.buttonContainter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: -30px;
}

.button {
    background-color: var(--border-color);
    transition: background-color 0.5s ease-in-out;
    border-radius: 4px;
    font-family: 'Golos Regular';
    padding: 4px 8px;
    cursor: pointer;
    color: #8D96B2;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.legendAddition {
    background-color: var(--border-color);
    transition: background-color 0.5s ease-in-out;
    margin-right: 4px;
    border-radius: 4px;
    cursor: auto;
    font-family: 'Golos Regular';
    padding: 4px 8px;
    color: #8D96B2;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttonSelected {
    background: #62687F;
    color: #FFF;
}


