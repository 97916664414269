.sliderMenu {
    position: relative;
    height: 100%;
    background-color: var(--container-bg-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 2px solid var(--border-color);
    z-index: 2;
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in, border-right 0.5s ease-in;
}

.sliderMenu__icon {
    height: 40px;
    margin-left: 19px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.agatha {
    font-size: 18px;
    font-family: 'Golos Regular';
}

.tender {
    font-size: 18px;
    font-family: 'Golos Bold';
}

.icoText,
.icoTextOpen {
    color: var(--text-color);
    margin-left: 12px;
    margin-top: 10px;
    opacity: 0;
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: color 0.5s ease-in, opacity 0.9s, max-width 0.5s;
}

.icoTextOpen {
    opacity: 1;
    max-width: 200px;
}

.sliderMenu__toggle {
    position: absolute;
    right: -15px;
    top: 65px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--sliderBtn-bg-color);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 999;
    transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.sliderMenu__toggle img {
    position: absolute;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

}

.sliderMenu__items {
    margin-top: 20px;
}

.sliderMenu__item {
    padding: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.sliderMenu__itemImage {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    transition: transform 0.5s ease-in-out;
}

.sliderMenu__itemText {
    margin-left: 10px;
    white-space: nowrap;
    font-family: 'Golos Regular';
    font-size: 16px;
    transition: color 0.5s ease-in, opacity 0.9s, max-width 0.5s;
    opacity: 0;
    max-width: 0;
    overflow: hidden;
}

.sliderMenu__itemTextOpen {
    opacity: 1;
    max-width: 200px;
}
