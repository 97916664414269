.container {
    display: flex;
    flex-direction: column;
}

.gif {
    display: block;
    margin: 20px auto;
    max-width: 300px;
    max-height: 300px;
}

.title {
    margin-bottom: 20px;
}

.description {
    margin-bottom: 20px;
}
