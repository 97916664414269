.formControl {
    width: 100%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.errorText {
    color: #FF0000;
    font-size: 12px;
}

.errorMessage {
    color: red;
    text-align: center;
    margin-top: 10px;
}


.icoDelete {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.listContainer {
    border: 1px solid #8D96B2;
    border-radius: 5px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    max-height: 50vh !important;
    overflow-y: auto;
    padding: 0.5em !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 0.2em !important;
}

.listSubheader {
    background-color: transparent !important;
    padding-left: 10px;
    font-size: 12px !important;
    border-radius: 5px;
    line-height: 1px !important;
    margin-bottom: -8px;
    margin-top: 5px;
    color: #333;
    width: 100%;
}

.spanText {
    color: #62687F
}

.listContainer::-webkit-scrollbar {
    width: 5px;
}

.listContainer::-webkit-scrollbar-thumb {
    background: #A2C4FF;
    border-radius: 10px;
}

.listContainer::-webkit-scrollbar-thumb:hover {
    background: #D0E1FF;
}

