.mainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1%;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
}

.sameJcHeader {
    flex-direction: row;
    justify-content: space-between;
}

.dataContainer {
    overflow-y: auto;
    flex-grow: 1;
    padding: 1%;
}

.morePadding {
    padding: 2%;
}

.legendAddition {
    background: #F7FAFC;
    margin-right: 20px;
    border-radius: 4px;
    cursor: auto;
    font-family: 'Golos Regular';
    padding: 2px 8px;
    color: #8D96B2;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttondAddition {
    margin: 0 auto;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #F7FAFC;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Golos Regular';
    color: #8D96B2;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 2%;
    margin-bottom: 10px;
    align-items: center;
}

.closDiv {
    display: flex;
    align-content: center;

}

.closeIcon {
    cursor: pointer;
}

.row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.content {
    display: flex;
    gap: 5px;
    align-items: center;
}

.labelsContainer {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.value {
    color: #8D96B2;
    font-family: "Golos Regular";
}

.label {
    color: #26252D;
    font-family: "Golos Bold";
}

