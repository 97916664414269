.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 1em;
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden;
}

.filterSection {
    flex: 1;
}

.footer {
    position: sticky;
    z-index: 1;
    min-height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2em;
}

.footerButtonClose {
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    gap: 0.5em
}

.footerBtnText {
    color: #8D96B2;
    font-size: 16px;
    line-height: 24px;
}

.searchButton {
    background-color: #4A3DFD;
    color: white;
    border: none;
    padding: 12px 16px;
    border-radius: 10px;
    cursor: pointer;
}

.accordionHeader {
    margin-top: 0.5em;
    padding: 10px 0px;
    display: flex;
    justify-content: flex-start;
    gap: 1em;
    align-items: center;
    cursor: pointer;
}

.accordionHeaderTitle {
    font-family: "Golos Medium";
}

.accordionIcon {
    transition: transform 0.3s ease;
}

.rotateIcon {
    transform: rotate(180deg);
}

.accordionContent {
    display: none;
    padding: 0.5em;
    margin-left: 2em;
}

.accordionOpen {
    display: block;
}

.addButton {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    background: rgba(118, 127, 254, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #4A3DFD;
    cursor: pointer;
}

.datePickerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.dataPickerContainerSmG {
    gap: 0.5em
}
@media (max-width: 1290px) {
    .datePickerContainer {
        flex-direction: column;
        align-items: flex-start;
    }
}

.pickerElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.datePickerLabel {
    margin-bottom: 0.2em;
}


.customDatePickerInput {
    margin-top: 0.5em;
    border: none;
    background: #F7FAFC;
    border-radius: 4px;
    padding: 0.5em;
    font-size: 14px;
    font-family: "Golos Regular" !important;
    color: #495057;
    width: 100%;
}

.customDatePickerIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: -0.8em;
    z-index: 0
}

.customDatePickerWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.priceInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
}

.priceInput {
    background: #F7FAFC;
    border-radius: 4px;
    border: none;
    padding: 0.5em;
    width: 165px;
}

.checkBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
}

.checkBoxContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.titleSearch {
    margin-left: -0.2em;
    cursor: pointer;
    background: #F7FAFC;
    padding: 0.5em;
    border-radius: 8px;
}
