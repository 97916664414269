.appContainer {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    display: flex;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
}

.contentContainer {
    margin: 20px;
}

.mainContainer {
    overflow: hidden;
    width: 100%;
    min-height: 100%;
    background-color: var(--main-container-bg-color);
    transition: background-color 0.5s ease-in;
}

