
.content {
    padding: 10px
}

.total {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin-bottom: 48px;
    color: var(--text-color);
    transition: color 0.5s ease-in;
    font-family: "Golos Regular";
    gap: 20px;
}

.totalPrice {
    font-family: "Golos Regular";
    font-size: 72px;
}

.items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    font-family: "Golos Regular";
}

.item {
    flex: 1 1 calc(50% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.label {
    font-size: 12px;
    color: #8D96B2;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    word-break: break-all;
}

.value {
    color: var(--text-color);
    transition: color 0.5s ease-in;
    font-size: 15px;
}

.label,
.value {
    word-wrap: break-word;
    overflow-wrap: break-word;
}





