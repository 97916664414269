
.tableContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tableRow {
    cursor: pointer;
}

.availableContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}

.detailsItem {
    display: flex;
    flex-direction: row;
}

.usersContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}

.header {
    font-size: 16px;
}

.icoContainer {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px
}

.icoTest {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.actionHeader {
    text-align: center !important;
    width: 10%;
}

.selectedRow {
    background-color: #DADBFF;
    transition: background-color 0.3s ease-in-out;
}

.column1 {
    width: 15%;
}

.column2 {
    width: 35%;
}

.column3 {
    width: 40%;
}

.column4 {
    width: 10%;
}

.moreText {
    color: #4A3DFD;
}
