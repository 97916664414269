.switcherContainer {
    position: fixed;
    bottom: 64px;
    left: 10px;
    z-index: 1000;
    display: flex;
    background-color: var(--switcher-container-bg-color);
    transition: background-color 0.5s ease-in, width 0.5s ease-in;
    border-radius: 24px;
}

.innerContainer {
    transition: width 0.5s ease-in;
    overflow: hidden;
    padding: 4px;
    width: 180px;
    display: flex;
    gap: 4px;
}

.switcherItem {
    width: 80px;
    padding: 10px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switcherItem.active {
    background-color: var(--switcher-item-active-bg-color);
    transition: background-color 0.5s ease-in;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.switcherItem.inactive {
    background-color: transparent;
}

.active {
    background-color: white;
}

.inactive {
    background-color: transparent;
}
