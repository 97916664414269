.customModal {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow: auto;
}

.customModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.customModalTitle {
    font-family: "Golos Bold";
    font-size: 24px;
    margin: 0;
}

.customModalCloseButton {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
}

.customModalContent {
    margin-bottom: 20px;
}

.customModalFooter {
    display: flex;
    justify-content: flex-end;
}

.customModalButton {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #4A3DFD;
    color: white;
    font-size: 16px;
    min-width: 20%;
}

.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
}

.sendingContainer {
       display: flex;
       align-items: center;
       justify-content: center;
       margin-top: 10px;
       gap: 0.2em;
}

.customModalButton.singleButton {
    min-width: 40%;
    margin: 1em auto auto auto;
}

.centerButton {
    display: flex;
    justify-content: center;
}

.redButton {
    background-color: red;
}

.errorMessage {
    color: red;
    text-align: center;
}
