.successPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    text-align: center;
    padding: 30px;
    background: white;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.container p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.container .details .email {
    font-weight: bold;
    color: #333;
}

.goHome {
    display: inline-block;
    margin-top: 1em;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #315EFB;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.goHome:hover {
    background-color: #315EFB;
    opacity: 90%;
}
