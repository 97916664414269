.container {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 24px 20px 16px;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(-10px);
}

.lastChild {
    margin-left: auto;
    position: absolute;
    right: 0;
}

.selected {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    gap: 0.1em;
    align-content: center;
    height: 30px;
    border-radius: 6px;
    background: #62687F;
    font-size: 16px;
    color: white;
    cursor: pointer;
}

.selected + .selected {
    margin-left: 0.5em;
}

.container.visible {
    opacity: 1;
    transform: translateY(0);
}

.clear {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    color: #8D96B2;
    font-family: "Golos Regular";
    gap: 8px;
    cursor: pointer;
}

.toggleContainer {
    display: flex;
    background-color: var(--switcherBt-bg-color);
    transition: background-color 0.3s ease-in-out;
    padding: 5px;
    position: relative;
    width: max-content;
    margin: auto;
    border-radius: 10px;
}

.toggleButton {
    font-family: "Golos Regular";
    color: var(--switcherBt-text-color);
    background-color: transparent;
    border: none;
    padding: 8px 20px;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-size: 16px;
    border-radius: 8px;
}

.twMonthButton {
    padding-right: 2px;
    padding-left: 10px;
}

.toggleButtonActive {
    background-color: white;
    color: #4B72FB;
}

.customSwitch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.customSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}


.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}




