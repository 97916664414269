.contentContainer {
    display: flex;
    flex-direction: column;
    margin: 40px 30px 24px 32px;
    font-family: "Golos Regular";
}

.contentHeader {
    color: var(--text-color);
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-family: "Golos Medium";
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.additionalMr {
    margin-top: 1em;
}

.ulTitle {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.list {
    color: var(--text-color);
    height: calc(100% - 200px);
}

.list ul {
    list-style-type: none;
    padding-left: 0;
}

.list ul li {
    font-size: 14px;
    line-height: 1.5;
}

.list ul {
    padding-left: 0;
}

.list ol li {
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
}

.closeXIcon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
}

.listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.itemText {
    font-size: 14px;
    flex-grow: 1;
    word-wrap: break-word;
    max-width: calc(100% - 30px - 50px);
    margin-right: 40px;
}

.removeItem {
    color: #4A3DFD;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}

.removeAllButtonContainer {
    cursor: pointer;
    width: 135px;
    height: 35px;
    display: flex;
    border-radius: 8px;
    background: #E1E7EF;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.nestedItem {
    margin-left: 20px;
}

.btnContainter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hidden {
    visibility: hidden;
}

.tabContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.companyFullName {
    font-family: "Golos Medium";
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.companyShortName {
    color: #8D96B2;
}
.tab {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #6B6B6B;
    border-bottom: 2px solid transparent;
}

.tab:hover {
    color: #4A3DFD;
}

.activeTab {
    color: #4A3DFD;
    border-bottom: 2px solid #4A3DFD;
}

.tabContent {
    color: var(--text-color);
    font-size: 14px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.generalInfoContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.infoColumn {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.infoItem {
    display: flex;
    flex-direction: column;
}

.infoItem strong {
    margin-bottom: 5px;
}

.subInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 1em;
}

strong {
    color: #8D96B2;
}

.infoRow {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}

.activityItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.activityItem strong {
    margin-right: 5px;
}

.activityNumber {
    margin-right: 10px;
    flex-shrink: 0;
}

.activityDescription {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.primaryLabel {
    color: #8D96B2;
    margin-left: 10px;
}
