.header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    position: sticky;
    z-index: 9999;
    top: 0;
    padding: 20px;
    padding-right: 4em;
    margin: auto;
}

.logo {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
}

.headerTitleMain {
    display: flex;
    flex-direction: row;
    font-size: 22px;
}

.headerTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.headerSubtitle {
    font-size: 11px;
    margin-top: 1.5px;
    color: #1F283E;
    cursor: pointer;
}

.agatha {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.tender {
    font-family: "Golos Medium";
    cursor: pointer;
}

@media (max-width: 1024px) {
    .header {
        background: #FFFFFF;
        padding: 10px;
        padding-right: 2.1em;
        padding-left: 2.1em;
    }

    .agathaLogo {
        margin-top: 0.2em;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 767px) {
    .header {
        padding-right: 0.5em;
        padding-left: 0.8em;
    }
}



